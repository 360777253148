(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
var AuthParams;

AuthParams = (function() {
  var STORAGE_ENGINES, self;
  STORAGE_ENGINES = [window.localStorage, window.sessionStorage];
  self = {
    get: function(key) {

      /*
      Previously it was stored in sessionStorage.
       */
      var i, len, storageEngine;
      for (i = 0, len = STORAGE_ENGINES.length; i < len; i++) {
        storageEngine = STORAGE_ENGINES[i];
        if (key in storageEngine) {
          return storageEngine[key];
        }
      }
      throw new Error("couldn't find " + key + " in any storage engine");
    },
    set: function(key, value) {
      var i, len, results, storageEngine;
      results = [];
      for (i = 0, len = STORAGE_ENGINES.length; i < len; i++) {
        storageEngine = STORAGE_ENGINES[i];
        results.push(storageEngine.setItem(key, value));
      }
      return results;
    }
  };
  return self;
})();

module.exports = AuthParams;


},{}],2:[function(require,module,exports){
var AuthParams, Promise;

Promise = require('promise/lib/es6-extensions');

AuthParams = require('./authparams.coffee');

module.exports = (function() {
  var self;
  self = {
    addAuthHeader: function(xhr) {
      return xhr.setRequestHeader('Authorization', "Bearer " + (AuthParams.get('token')));
    },
    get: function(path, skip_auth) {
      return new Promise(function(resolve, reject) {
        var server, xhr;
        xhr = new XMLHttpRequest();
        server = '';
        if (skip_auth) {
          server = window.location.protocol + "//" + window.location.hostname;
        } else {
          server = AuthParams.get('server');
        }
        xhr.open('GET', server + "/" + path, true);
        xhr.setRequestHeader('Accept', 'application/json');
        if (!skip_auth) {
          self.addAuthHeader(xhr);
        }
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              return resolve(JSON.parse(xhr.responseText));
            } else {
              return reject({
                status: xhr.status
              });
            }
          }
        };
        return xhr.send();
      });
    },
    post: function(path, data) {

      /*
      Posts a POJO as JSON.
       */
      return new Promise(function(resolve, reject) {
        var xhr;
        xhr = new XMLHttpRequest();
        xhr.open('POST', (AuthParams.get('server')) + "/" + path, true);
        self.addAuthHeader(xhr);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              return resolve(JSON.parse(xhr.responseText));
            } else {
              return reject({
                status: xhr.status
              });
            }
          }
        };
        return xhr.send(JSON.stringify(data));
      });
    },
    put: function(path, data) {

      /*
      Puts a POJO as JSON.
       */
      return new Promise(function(resolve, reject) {
        var xhr;
        xhr = new XMLHttpRequest();
        xhr.open('PUT', (AuthParams.get('server')) + "/" + path, true);
        self.addAuthHeader(xhr);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              return resolve(JSON.parse(xhr.responseText));
            } else {
              return reject({
                status: xhr.status
              });
            }
          }
        };
        return xhr.send(JSON.stringify(data));
      });
    },
    "delete": function(path, data) {
      return new Promise(function(resolve, reject) {
        var xhr;
        xhr = new XMLHttpRequest();
        xhr.open('DELETE', (AuthParams.get('server')) + "/" + path, true);
        self.addAuthHeader(xhr);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              return resolve(JSON.parse(xhr.responseText));
            } else {
              return reject({
                status: xhr.status
              });
            }
          }
        };
        return xhr.send(JSON.stringify(data));
      });
    }
  };
  return self;
})();


},{"./authparams.coffee":1,"promise/lib/es6-extensions":13}],3:[function(require,module,exports){
module.exports={
  "categories": [
    {
      "id": "main",
      "title": "Collect",
      "svg": "icon-ice-apps",
      "color": "#2196f3",
      "tiles": [
        {
          "id": "entry",
          "type": "launch",
          "title": "Entry",
          "svg": "entry-icon",
          "info": "Enter and sign clinical data, view and respond to queries and view resources for the trial.",
          "url": "/entry/",
          "target": "_self",
          "capability": "icewebentry"
        },
        {
          "id": "loader",
          "type": "launch",
          "title": "Loader",
          "svg": "loader-icon",
          "info": "Set up data loaders and manually upload local lab data.",
          "url": "/loader/",
          "target": "_self",
          "capability": "icewebloader"
        },
        {
          "id": "esourcetraining",
          "type": "launch",
          "title": "eSource</br>Training",
          "svg": "icon-esource-training",
          "info": "Training for eSource App",
          "url": "/esourcetraining/",
          "target": "_self",
          "capability": "icewebesourcetraining"
        }
      ]
    },
    {
      "id": "integration",
      "title": "Action",
      "svg": "encapsia-icon",
      "color": "#4cb050",
      "tiles": [
        {
          "id": "conduct",
          "type": "launch",
          "title": "Conduct",
          "svg": "conduct-icon",
          "info": "View clinical data, acknowledge queries and perform verification of tracks.",
          "url": "/conduct/",
          "target": "_self",
          "capability": "icewebconduct"
        },
        {
          "id": "coding",
          "type": "launch",
          "title": "Coding",
          "svg": "coding-icon",
          "info": "Assign universal codes to clinical trial data for easier analysis and submission to regulatory authorities.",
          "url": "/coding/",
          "target": "_self",
          "capability": "icewebcoding"
        },
        {
          "id": "inventory",
          "type": "launch",
          "title": "Inventory",
          "svg": "inventory-icon",
          "info": "Manage site shipments and kits.",
          "url": "/inventory/",
          "target": "_self",
          "capability": "icewebinventory"
        },
        {
          "id": "review",
          "type": "launch",
          "title": "Review",
          "svg": "review-icon",
          "info": "Review, reconcile, and annotate loaded third party data.",
          "url": "/loaderreview/",
          "target": "_self",
          "capability": "icewebloaderreview"
        }
      ]
    },
    {
      "id": "admin",
      "title": "Identify",
      "svg": "encapsia-icon",
      "color": "#243142",
      "tiles": [
        {
          "id": "insights",
          "type": "launch",
          "title": "Insights",
          "svg": "insights-icon",
          "info": "Customisable dashboard of visualisations allowing unique insight into your study data.",
          "url": "/insights/",
          "target": "_self",
          "capability": "icewebinsights"
        },
        {
          "id": "analytics",
          "type": "launch",
          "title": "Analytics",
          "svg": "analytics-icon",
          "info": "Interactive computing environment for analysing data and creating insights.",
          "url": "/analytics/",
          "capability": "icewebanalytics"
        }
      ]
    },
    {
      "id": "server",
      "title": "Management",
      "svg": "encapsia-icon",
      "color": "#ef4135",
      "tiles": [
        {
          "id": "builder",
          "type": "launch",
          "title": "Builder",
          "svg": "builder-icon",
          "info": "Create forms, place them within visits and set up the study schedule. Configure validation rules and derivations.",
          "url": "/builder/",
          "target": "_self",
          "capability": "icewebbuilder"
        },
        {
          "id": "manager",
          "type": "launch",
          "title": "Manager",
          "svg": "manager-icon",
          "info": "Administer users, sites, roles, email templates, trial parameters, view user activity and revoke access.",
          "url": "/manager/",
          "target": "_self",
          "capability": "icewebmanager"
        },
        {
          "id": "archiver",
          "type": "launch",
          "title": "Archiver",
          "svg": "archiver-icon",
          "info": "Create an archive of the trial data including the full audit trail.",
          "url": "/archiver/",
          "target": "_self",
          "capability": "icewebarchiver"
        },
        {
          "id": "fixtures",
          "type": "launch",
          "title": "Fixture",
          "svg": "fixture-icon",
          "info": "Manage database fixtures.",
          "url": "/fixture_manager",
          "external": true,
          "capability": "server.fixturemanager"
        }
      ]
    },
    {
      "id": "build",
      "title": "Build",
      "svg": "deploy-icon",
      "color": "#FE9802",
      "tiles": [
        {
          "id": "deploy",
          "type": "launch",
          "title": "Deploy",
          "svg": "deploy-icon",
          "info": "Deploy and activate trial configuration versions across servers.",
          "url": "/deploy/",
          "target": "_self",
          "capability": "icewebdeploy"
        }
      ]
    },
    {
      "id": "help",
      "title": "Help",
      "svg": "encapsia-icon",
      "color": "#5a6f8a",
      "capability": "server.documentation",
      "tiles": [
        {
          "id": "doc",
          "type": "launch",
          "title": "Docs",
          "svg": "docs-icon",
          "info": "Full technical documentation on the REST API, entities, DB design and engineering approach.",
          "url": "/documentation/",
          "external": true
        },
        {
          "id": "overview",
          "type": "launch",
          "title": "Overview",
          "svg": "overview-icon",
          "info": "Overview of Encapsia system",
          "url": "/overview/",
          "capability": "iceweboverview"
        }
      ]
    }
  ]
}

},{}],4:[function(require,module,exports){
var getVariables;

getVariables = function() {

  /*
  Returns environment variables to enable mocking.
   */
  var devVars;
  devVars = window.sessionStorage.getItem('__iceLoginEnv');
  if (devVars != null) {
    return JSON.parse(devVars);
  } else {
    return {
      server: "https://" + window.location.host,
      trial: localStorage.getItem('short_trial_name')
    };
  }
};

module.exports = getVariables();


},{}],5:[function(require,module,exports){
var Login;

Login = require('./login.coffee');

document.addEventListener('DOMContentLoaded', Login.main);


},{"./login.coffee":7}],6:[function(require,module,exports){
var HttpRequests, Promise, browserInfo;

Promise = require('promise/lib/es6-extensions');

browserInfo = require('browser-info');

HttpRequests = require('../../commons/httprequest.coffee');

module.exports = (function() {
  var self;
  self = {
    stashedLocation: void 0,
    getGeolocation: function() {

      /*
      Returns a promise that resolves to the Position object 
      or is rejected with an error.
       */
      return new Promise(function(resolve, reject) {
        var geolocation;
        geolocation = window.navigator.geolocation;
        if (geolocation != null) {
          return geolocation.getCurrentPosition(resolve, reject);
        } else {
          return reject('browser does not support geolocation');
        }
      });
    },
    _setStashedLocation: function(position) {
      return self.stashedLocation = position;
    },
    stashGeolocation: function() {

      /*
      Used to immediately acquire the user's location on page load 
      so it doesn't block the login process.
      
      Dismissing the geolocation prompt in IE11 didn't trigger either the 
      success or error callback, so this is necessary.
       */
      return self.getGeolocation().then(self._setStashedLocation);
    },
    getDeviceDescription: function() {
      var browser;
      browser = browserInfo();
      return "Browser " + browser.name + " " + browser.version + " on " + browser.os;
    },
    logDevice: function() {
      return new Promise(function(resolve, reject) {
        var data;
        data = {
          device: self.getDeviceDescription()
        };
        return HttpRequests.put('v1/whoami', data).then(resolve, reject);
      });
    },
    uploadPosition: function(position) {
      var data;
      data = {
        what: 'Known user identified',
        where: position.coords.latitude + "," + position.coords.longitude,
        how: self.getDeviceDescription()
      };
      return HttpRequests.post('v1/activities', data);
    },
    tryLogEverything: function() {

      /*
      Returns a promise that resolves once it has tried to log everything.
      
      The promise returned should always resolve, even if one of the individual 
      logging steps rejects.
      
      It's merely used to
       */
      return new Promise(function(resolve, reject) {
        var promises;
        promises = [self.logDevice()];
        if (self.stashedLocation != null) {
          promises.push(self.uploadPosition(self.stashedLocation));
        }
        return Promise.all(promises).then(resolve, resolve);
      });
    }
  };
  return self;
})();


},{"../../commons/httprequest.coffee":2,"browser-info":10,"promise/lib/es6-extensions":13}],7:[function(require,module,exports){
var AuthParams, HttpRequests, Log, LoginAppState, LoginBox, LoginSteps, Promise, addToolbelt, becomeAuthenticated, getAccessibleUrls, getAction, getUrlIfSingleApp, main, patchSessionStorage, prepareForLogin, prepareForLogout, queryHash, queryParams, querystring, readTrialParams, showAvailableProviders, showSplash,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Promise = require('promise/lib/es6-extensions');

LoginSteps = require('./steps.coffee');

Log = require('./log.coffee');

querystring = require('query-string');

HttpRequests = require('../../commons/httprequest.coffee');

AuthParams = require('../../commons/authparams.coffee');

queryParams = querystring.parse(window.location.search);

queryHash = window.location.hash;

LoginAppState = {
  set: function(state) {
    return document.querySelector('body').setAttribute('data-state', state);
  }
};

addToolbelt = function() {
  var script;
  script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '/launch/icetoolbelt.js';
  window.onIceToolbeltLoad = function() {
    return window.IceToolbelt.Launch.open();
  };
  return document.body.appendChild(script);
};

showSplash = function() {
  var singleAppUrl;
  singleAppUrl = getUrlIfSingleApp();
  if (singleAppUrl) {
    if (document.referrer.indexOf(singleAppUrl) === -1) {
      window.location.replace(singleAppUrl);
      return;
    }
  }
  LoginAppState.set('splash');
  addToolbelt();
  return document.querySelector('.splash .user-bar .name').textContent = window.localStorage.name;
};

getAccessibleUrls = function(caps) {

  /*
    Checks capabilities against the current apps and returns
    a list of accessible urls.
   */
  var apps;
  if (!caps) {
    caps = window.localStorage.getItem('capabilities').split(',');
  }
  apps = require('../../commons/launch/apps.json').categories;
  return apps.reduce(function(acc, category) {
    category.tiles.forEach(function(cat) {
      var ref;
      if (cat.capability && (ref = cat.capability, indexOf.call(caps, ref) >= 0)) {
        return acc.push(cat.url);
      }
    });
    return acc;
  }, []);
};

getUrlIfSingleApp = function(caps) {

  /*
    Checks capabilities against the current apps and returns false or
    url for the single app.
   */
  var urlsForHeldCaps;
  urlsForHeldCaps = getAccessibleUrls(caps);
  if (urlsForHeldCaps.length === 1) {
    return urlsForHeldCaps[0];
  } else {
    return false;
  }
};

readTrialParams = function() {

  /*
   The top bar is common across all applications, and it relies on information from local storage.
   So we need to fill in some trial param informations in local storage.
   */
  return new Promise(function(resolve, reject) {
    return HttpRequests.get("v1/trialparams").then(function(resp) {
      var i, len, param, ref, trialparams;
      trialparams = resp != null ? (ref = resp.result) != null ? ref.trialparams : void 0 : void 0;
      for (i = 0, len = trialparams.length; i < len; i++) {
        param = trialparams[i];
        if (param.key === 'short_trial_name') {
          window.localStorage[param.key] = param.value;
        }
      }
      return resolve(true);
    })["catch"](function(reason) {
      return reject("failed to reach endpoint");
    });
  });
};

patchSessionStorage = function() {

  /*
  To remain backwards compatible with existing webapps
  we must patch copy across from localStorage.
  
  TODO: remove this once all apps have been updated.
   */
  var key, results;
  results = [];
  for (key in window.localStorage) {
    results.push(window.sessionStorage[key] = window.localStorage[key]);
  }
  return results;
};

becomeAuthenticated = function() {

  /*
  Called once the user is authenticated.
   */
  patchSessionStorage();
  if (queryParams.next != null) {
    return window.location.replace("" + queryParams.next + queryHash);
  } else if (queryParams.action === 'show-splash') {
    return showSplash();
  } else {
    return LoginSteps.getLastApp().then(function(appUrl) {
      var accessibleUrls, url, user;
      accessibleUrls = getAccessibleUrls();
      if (indexOf.call(accessibleUrls, appUrl) >= 0) {
        return window.location.replace(appUrl);
      } else {
        user = encodeURIComponent(AuthParams.get('email'));
        url = "v1/userpreferences/" + user + "/last_app_url";
        HttpRequests["delete"](url, {
          reason: "app " + appUrl + " not accessible for user"
        });
        return showSplash();
      }
    })["catch"](showSplash);
  }
};

LoginBox = function(root) {
  var _errorMessage, _errorMessageTitle, self;
  _errorMessage = root.querySelector('.error-message-text');
  _errorMessageTitle = root.querySelector('.error-message-title');
  self = {
    setError: function(msg, provider) {
      var btn, btns, deniedProviderBtn, i, len;
      LoginAppState.set('error');
      _errorMessageTitle.textContent = "Access denied";
      _errorMessage.textContent = msg;
      if (provider != null) {
        deniedProviderBtn = root.querySelector("." + provider + "-provider");
        if (deniedProviderBtn != null) {
          btns = document.querySelectorAll(".providers button");
          for (i = 0, len = btns.length; i < len; i++) {
            btn = btns[i];
            btn.className += " clear-error";
          }
          return deniedProviderBtn.className = provider + "-provider has-error";
        }
      }
    },
    _performLogin: function(provider) {

      /*
      Wraps the entire login process in a promise that
      rejects if any step rejects *OR* throws an error.
       */
      return new Promise(function(resolve, reject) {
        LoginSteps.openProviderWindow(provider);
        LoginSteps.getToken().then(function(token) {
          LoginAppState.set('loading');
          return LoginSteps.populateAuthParams(provider, token);
        }).then(Log.tryLogEverything).then(readTrialParams).then(becomeAuthenticated).then(resolve, reject);
      });
    },
    login: function(provider) {
      return self._performLogin(provider)["catch"](function(reason) {
        self.setError(reason, provider);
        return console.error('something went wrong logging in', reason);
      });
    },
    attachProviderEvent: function(providerBtn) {
      var provider;
      provider = providerBtn.getAttribute('data-provider');
      return providerBtn.addEventListener('click', function() {
        return self.login(provider);
      });
    }
  };
  return self;
};

getAction = function() {
  return queryParams.action;
};

showAvailableProviders = function() {
  return new Promise(function(resolve, reject) {
    var skip_auth;
    skip_auth = true;
    return HttpRequests.get("", skip_auth).then(function(resp) {
      var btn, i, len, oauth2_providers, provider, ref, sign_in_text;
      oauth2_providers = resp != null ? (ref = resp.result) != null ? ref.oauth2_providers : void 0 : void 0;
      sign_in_text = document.querySelector(".container_sign_in");
      if (oauth2_providers.length === 1) {
        sign_in_text.parentNode.removeChild(sign_in_text);
      }
      for (i = 0, len = oauth2_providers.length; i < len; i++) {
        provider = oauth2_providers[i];
        btn = document.querySelector("." + provider + "-provider");
        if (btn) {
          btn.style.display = "block";
        }
        if (oauth2_providers.length === 1) {
          btn.querySelector("span").textContent = "Sign in";
        }
      }
      return resolve(true);
    })["catch"](function(reason) {
      var btn, btns, i, len;
      console.error('failed to get oauth2_providers', reason);
      btns = document.querySelectorAll('.providers button');
      for (i = 0, len = btns.length; i < len; i++) {
        btn = btns[i];
        btn.style.visibility = "visible";
      }
      return reject('failed to get oauth2_providers');
    });
  });
};

prepareForLogin = function() {
  var btns, loginBox;
  Log.stashGeolocation();
  loginBox = LoginBox(document.querySelector('.login-box'));
  btns = document.querySelectorAll('.providers button');
  Array.prototype.forEach.call(btns, loginBox.attachProviderEvent);
  return showAvailableProviders();
};

prepareForLogout = function() {
  var redirect;
  LoginAppState.set('loading');
  redirect = function() {
    return window.location.replace(queryParams.next != null ? "./?next=" + (encodeURIComponent(queryParams.next)) : './');
  };
  return LoginSteps.logout().then(redirect, redirect);
};

main = function() {
  var helpdesk_btn, img;
  img = new Image();
  img.src = 'img/encapsia-background-with-pattern.jpg';
  img.onload = function() {
    return document.getElementById("particles-js").classList.add('image-loaded');
  };
  helpdesk_btn = document.getElementById('helpdesk_btn');
  helpdesk_btn.addEventListener('click', function() {
    var target;
    target = document.getElementById('helpdesk');
    if (target.style.display === 'none') {
      return target.style.display = 'block';
    } else {
      return target.style.display = 'none';
    }
  });
  if (getAction() === 'logout') {
    return prepareForLogout();
  } else {
    return LoginSteps.isAlreadyLoggedIn().then(readTrialParams).then(becomeAuthenticated)["catch"](prepareForLogin);
  }
};

module.exports = {
  LoginAppState: LoginAppState,
  addToolbelt: addToolbelt,
  showSplash: showSplash,
  getUrlIfSingleApp: getUrlIfSingleApp,
  becomeAuthenticated: becomeAuthenticated,
  LoginBox: LoginBox,
  main: main
};


},{"../../commons/authparams.coffee":1,"../../commons/httprequest.coffee":2,"../../commons/launch/apps.json":3,"./log.coffee":6,"./steps.coffee":8,"promise/lib/es6-extensions":13,"query-string":14}],8:[function(require,module,exports){
var AuthParams, Env, HttpRequests, IMPORTANT_AUTH_PARAMS, Promise, _populateWhoami, getLastApp, getOrigin, getToken, isAlreadyLoggedIn, logout, missingAuthParam, openProviderWindow, populateAuthParams;

Promise = require('promise/lib/es6-extensions');

HttpRequests = require('../../commons/httprequest.coffee');

AuthParams = require('../../commons/authparams.coffee');

Env = require('./env.coffee');

IMPORTANT_AUTH_PARAMS = ['token', 'expires_at', 'capabilities'];

getOrigin = function() {
  if (window.location.origin != null) {
    return window.location.origin;
  } else {
    return window.location.protocol + "//" + window.location.host;
  }
};

openProviderWindow = function(provider) {

  /*
  Opens the provider's login screen in a new window.
  This is where the user types their username and password.
   */
  return window.open(Env.server + "/v1/login/using/" + provider + "?origin=" + (encodeURIComponent(getOrigin())));
};

getToken = function() {

  /*
  Returns a promise that resolves to the token that's sent 
  to this window via the `window.handleLoginSuccess` callback.
   */
  return new Promise(function(resolve, reject) {
    return window.handleLoginSuccess = function(msg) {

      /*
      Called by the ICE server after it receives a reply from 
      the OAuth2 provider.
      
      The old home app *primarily* used the Window Messaging API. 
      This wasn't working with IE11 on Sauce Labs, but was working 
      on a virtual machine.
      
      I've simply removed the Window Messaging approach.
      
      Note: the ICE server calls this function with the origin 
      as a parameter, but this isn't actually required due to 
      the same-origin policy.
       */
      var error, token;
      delete window.handleLoginSuccess;
      if (typeof msg !== 'string') {
        return reject("Expected provider to reply with a string, but got a " + (typeof msg));
      } else if (msg.indexOf('OK:') === 0) {
        token = msg.slice('OK:'.length);
        return resolve(token);
      } else if (msg.indexOf('ERROR:') === 0) {
        error = msg.slice('ERROR:'.length);
        return reject(error);
      } else {
        return reject("Unexpected reply from provider: " + msg);
      }
    };
  });
};

_populateWhoami = function() {
  return HttpRequests.get('v1/whoami').then(function(resp) {
    var prop, results, storedValue, value, whoami;
    whoami = resp.result;
    results = [];
    for (prop in whoami) {
      value = whoami[prop];
      storedValue = value != null ? value : '';
      results.push(AuthParams.set(prop, storedValue));
    }
    return results;
  });
};

populateAuthParams = function(provider, token) {

  /*
  All ICE apps expect some data to be in localStorage/sessionStorage.
  The most important is the token.
  There's also some other data that must be retrieved from the whoami endpoint.
   */
  var items, key, value;
  items = {
    auth_method: provider,
    token: token,
    trial: Env.trial,
    server: Env.server
  };
  for (key in items) {
    value = items[key];
    AuthParams.set(key, value);
  }
  return _populateWhoami();
};

getLastApp = function() {

  /*
  Looks up the user's last accessed app url in their user preferences.
   */
  return new Promise(function(resolve, reject) {
    HttpRequests.get("v1/userpreferences/" + (encodeURIComponent(AuthParams.get('email'))) + "/last_app_url").then(function(resp) {
      return resolve(resp.result.value);
    })["catch"](function(reason) {
      return reject("couldn't get last used app url due to: " + reason);
    });
  });
};

missingAuthParam = function() {

  /*
  Returns the first missing auth param or false if nothing is missing.
   */
  var e, i, len, param;
  for (i = 0, len = IMPORTANT_AUTH_PARAMS.length; i < len; i++) {
    param = IMPORTANT_AUTH_PARAMS[i];
    try {
      if (AuthParams.get(param) == null) {
        return param;
      }
    } catch (error1) {
      e = error1;
      return param;
    }
  }
  return false;
};

isAlreadyLoggedIn = function() {
  return new Promise(function(resolve, reject) {
    var missingParam;
    missingParam = missingAuthParam();
    if (missingParam !== false) {
      return reject("missing auth param '" + missingParam + "'");
    } else {
      return HttpRequests.get("v1/whoami").then(function(resp) {
        return resolve(true);
      })["catch"](function(reason) {
        return reject("failed to reach endpoint");
      });
    }
  });
};

logout = function() {
  var deleteAuthParams;
  deleteAuthParams = function() {
    var i, key, len, results;
    results = [];
    for (i = 0, len = IMPORTANT_AUTH_PARAMS.length; i < len; i++) {
      key = IMPORTANT_AUTH_PARAMS[i];
      window.localStorage.removeItem(key);
      results.push(window.sessionStorage.removeItem(key));
    }
    return results;
  };
  return HttpRequests["delete"]('v1/logout').then(deleteAuthParams, deleteAuthParams);
};

module.exports = {
  openProviderWindow: openProviderWindow,
  getToken: getToken,
  populateAuthParams: populateAuthParams,
  getLastApp: getLastApp,
  isAlreadyLoggedIn: isAlreadyLoggedIn,
  logout: logout
};


},{"../../commons/authparams.coffee":1,"../../commons/httprequest.coffee":2,"./env.coffee":4,"promise/lib/es6-extensions":13}],9:[function(require,module,exports){
(function (global){
"use strict";

// Use the fastest means possible to execute a task in its own turn, with
// priority over other events including IO, animation, reflow, and redraw
// events in browsers.
//
// An exception thrown by a task will permanently interrupt the processing of
// subsequent tasks. The higher level `asap` function ensures that if an
// exception is thrown by a task, that the task queue will continue flushing as
// soon as possible, but if you use `rawAsap` directly, you are responsible to
// either ensure that no exceptions are thrown from your task, or to manually
// call `rawAsap.requestFlush` if an exception is thrown.
module.exports = rawAsap;
function rawAsap(task) {
    if (!queue.length) {
        requestFlush();
        flushing = true;
    }
    // Equivalent to push, but avoids a function call.
    queue[queue.length] = task;
}

var queue = [];
// Once a flush has been requested, no further calls to `requestFlush` are
// necessary until the next `flush` completes.
var flushing = false;
// `requestFlush` is an implementation-specific method that attempts to kick
// off a `flush` event as quickly as possible. `flush` will attempt to exhaust
// the event queue before yielding to the browser's own event loop.
var requestFlush;
// The position of the next task to execute in the task queue. This is
// preserved between calls to `flush` so that it can be resumed if
// a task throws an exception.
var index = 0;
// If a task schedules additional tasks recursively, the task queue can grow
// unbounded. To prevent memory exhaustion, the task queue will periodically
// truncate already-completed tasks.
var capacity = 1024;

// The flush function processes all tasks that have been scheduled with
// `rawAsap` unless and until one of those tasks throws an exception.
// If a task throws an exception, `flush` ensures that its state will remain
// consistent and will resume where it left off when called again.
// However, `flush` does not make any arrangements to be called again if an
// exception is thrown.
function flush() {
    while (index < queue.length) {
        var currentIndex = index;
        // Advance the index before calling the task. This ensures that we will
        // begin flushing on the next task the task throws an error.
        index = index + 1;
        queue[currentIndex].call();
        // Prevent leaking memory for long chains of recursive calls to `asap`.
        // If we call `asap` within tasks scheduled by `asap`, the queue will
        // grow, but to avoid an O(n) walk for every task we execute, we don't
        // shift tasks off the queue after they have been executed.
        // Instead, we periodically shift 1024 tasks off the queue.
        if (index > capacity) {
            // Manually shift all values starting at the index back to the
            // beginning of the queue.
            for (var scan = 0, newLength = queue.length - index; scan < newLength; scan++) {
                queue[scan] = queue[scan + index];
            }
            queue.length -= index;
            index = 0;
        }
    }
    queue.length = 0;
    index = 0;
    flushing = false;
}

// `requestFlush` is implemented using a strategy based on data collected from
// every available SauceLabs Selenium web driver worker at time of writing.
// https://docs.google.com/spreadsheets/d/1mG-5UYGup5qxGdEMWkhP6BWCz053NUb2E1QoUTU16uA/edit#gid=783724593

// Safari 6 and 6.1 for desktop, iPad, and iPhone are the only browsers that
// have WebKitMutationObserver but not un-prefixed MutationObserver.
// Must use `global` or `self` instead of `window` to work in both frames and web
// workers. `global` is a provision of Browserify, Mr, Mrs, or Mop.

/* globals self */
var scope = typeof global !== "undefined" ? global : self;
var BrowserMutationObserver = scope.MutationObserver || scope.WebKitMutationObserver;

// MutationObservers are desirable because they have high priority and work
// reliably everywhere they are implemented.
// They are implemented in all modern browsers.
//
// - Android 4-4.3
// - Chrome 26-34
// - Firefox 14-29
// - Internet Explorer 11
// - iPad Safari 6-7.1
// - iPhone Safari 7-7.1
// - Safari 6-7
if (typeof BrowserMutationObserver === "function") {
    requestFlush = makeRequestCallFromMutationObserver(flush);

// MessageChannels are desirable because they give direct access to the HTML
// task queue, are implemented in Internet Explorer 10, Safari 5.0-1, and Opera
// 11-12, and in web workers in many engines.
// Although message channels yield to any queued rendering and IO tasks, they
// would be better than imposing the 4ms delay of timers.
// However, they do not work reliably in Internet Explorer or Safari.

// Internet Explorer 10 is the only browser that has setImmediate but does
// not have MutationObservers.
// Although setImmediate yields to the browser's renderer, it would be
// preferrable to falling back to setTimeout since it does not have
// the minimum 4ms penalty.
// Unfortunately there appears to be a bug in Internet Explorer 10 Mobile (and
// Desktop to a lesser extent) that renders both setImmediate and
// MessageChannel useless for the purposes of ASAP.
// https://github.com/kriskowal/q/issues/396

// Timers are implemented universally.
// We fall back to timers in workers in most engines, and in foreground
// contexts in the following browsers.
// However, note that even this simple case requires nuances to operate in a
// broad spectrum of browsers.
//
// - Firefox 3-13
// - Internet Explorer 6-9
// - iPad Safari 4.3
// - Lynx 2.8.7
} else {
    requestFlush = makeRequestCallFromTimer(flush);
}

// `requestFlush` requests that the high priority event queue be flushed as
// soon as possible.
// This is useful to prevent an error thrown in a task from stalling the event
// queue if the exception handled by Node.js’s
// `process.on("uncaughtException")` or by a domain.
rawAsap.requestFlush = requestFlush;

// To request a high priority event, we induce a mutation observer by toggling
// the text of a text node between "1" and "-1".
function makeRequestCallFromMutationObserver(callback) {
    var toggle = 1;
    var observer = new BrowserMutationObserver(callback);
    var node = document.createTextNode("");
    observer.observe(node, {characterData: true});
    return function requestCall() {
        toggle = -toggle;
        node.data = toggle;
    };
}

// The message channel technique was discovered by Malte Ubl and was the
// original foundation for this library.
// http://www.nonblocking.io/2011/06/windownexttick.html

// Safari 6.0.5 (at least) intermittently fails to create message ports on a
// page's first load. Thankfully, this version of Safari supports
// MutationObservers, so we don't need to fall back in that case.

// function makeRequestCallFromMessageChannel(callback) {
//     var channel = new MessageChannel();
//     channel.port1.onmessage = callback;
//     return function requestCall() {
//         channel.port2.postMessage(0);
//     };
// }

// For reasons explained above, we are also unable to use `setImmediate`
// under any circumstances.
// Even if we were, there is another bug in Internet Explorer 10.
// It is not sufficient to assign `setImmediate` to `requestFlush` because
// `setImmediate` must be called *by name* and therefore must be wrapped in a
// closure.
// Never forget.

// function makeRequestCallFromSetImmediate(callback) {
//     return function requestCall() {
//         setImmediate(callback);
//     };
// }

// Safari 6.0 has a problem where timers will get lost while the user is
// scrolling. This problem does not impact ASAP because Safari 6.0 supports
// mutation observers, so that implementation is used instead.
// However, if we ever elect to use timers in Safari, the prevalent work-around
// is to add a scroll event listener that calls for a flush.

// `setTimeout` does not call the passed callback if the delay is less than
// approximately 7 in web workers in Firefox 8 through 18, and sometimes not
// even then.

function makeRequestCallFromTimer(callback) {
    return function requestCall() {
        // We dispatch a timeout with a specified delay of 0 for engines that
        // can reliably accommodate that request. This will usually be snapped
        // to a 4 milisecond delay, but once we're flushing, there's no delay
        // between events.
        var timeoutHandle = setTimeout(handleTimer, 0);
        // However, since this timer gets frequently dropped in Firefox
        // workers, we enlist an interval handle that will try to fire
        // an event 20 times per second until it succeeds.
        var intervalHandle = setInterval(handleTimer, 50);

        function handleTimer() {
            // Whichever timer succeeds will cancel both timers and
            // execute the callback.
            clearTimeout(timeoutHandle);
            clearInterval(intervalHandle);
            callback();
        }
    };
}

// This is for `asap.js` only.
// Its name will be periodically randomized to break any code that depends on
// its existence.
rawAsap.makeRequestCallFromTimer = makeRequestCallFromTimer;

// ASAP was originally a nextTick shim included in Q. This was factored out
// into this ASAP package. It was later adapted to RSVP which made further
// amendments. These decisions, particularly to marginalize MessageChannel and
// to capture the MutationObserver implementation in a closure, were integrated
// back into ASAP proper.
// https://github.com/tildeio/rsvp.js/blob/cddf7232546a9cf858524b75cde6f9edf72620a7/lib/rsvp/asap.js

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],10:[function(require,module,exports){
/* globals navigator*/

'use strict';


function info(){
  var ua = navigator.userAgent;
  var tem;
  var os;

  var match = ua.match(/(opera|chrome|safari|firefox|edge|trident(?=\/))\/?\s*?(\S+)/i) || [];


  if (ua.indexOf('Win') !== -1) {
    os = 'Windows';
  }
  if (ua.indexOf('Mac') !== -1) {
    os = 'OS X';
  }
  if (ua.indexOf('X11') !== -1) {
    os = 'UNIX';
  }
  if (ua.indexOf('Linux' ) !== -1) {
    os = 'Linux';
  }
  if (ua.indexOf('Android') !== -1) {
    os = 'Android';
  }
  if (/iPad|iPhone|iPod/.test(ua)) {
    os = 'iOS';
  }
  if (ua.indexOf('Windows Phone') !== -1) {
    os = 'Windows Phone';
  }

  tem = ua.match(/\bIEMobile\/(\S+[0-9])/);
  if (tem !== null) {
    return {
      name: 'IEMobile',
      version: tem[1].split('.')[0],
      fullVersion: tem[1],
      os: os
    };
  }

  if (/trident/i.test(match[1])) {
    tem = /\brv[ :]+(\S+[0-9])/g.exec(ua) || [];
    return {
      name: 'IE',
      version: tem[1].split('.')[0],
      fullVersion: tem[1],
      os: os
    };
  }

  if (match[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem !== null) {
      return {
        name: 'Opera',
        version: tem[1].split('.')[0],
        fullVersion: tem[1],
        os: os
      };
    }

    tem = ua.match(/\bEdge\/(\S+)/);
    if (tem !== null) {
      return {
        name: 'Edge',
        version: tem[1].split('.')[0],
        fullVersion: tem[1],
        os: os
      };
    }
  }
  match = match[2]? [match[1], match[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if (match[0] !== 'Chrome') {
    var tem = ua.match(/version\/(\S+)/i)
    if (tem !== null && tem !== '') {
      match.splice(1, 1, tem[1]);
    }
  }
  return {
    name: match[0],
    version: match[1].split('.')[0],
    fullVersion: match[1],
    os: os
  };
}

module.exports = info;

},{}],11:[function(require,module,exports){
/*
object-assign
(c) Sindre Sorhus
@license MIT
*/

'use strict';
/* eslint-disable no-unused-vars */
var getOwnPropertySymbols = Object.getOwnPropertySymbols;
var hasOwnProperty = Object.prototype.hasOwnProperty;
var propIsEnumerable = Object.prototype.propertyIsEnumerable;

function toObject(val) {
	if (val === null || val === undefined) {
		throw new TypeError('Object.assign cannot be called with null or undefined');
	}

	return Object(val);
}

function shouldUseNative() {
	try {
		if (!Object.assign) {
			return false;
		}

		// Detect buggy property enumeration order in older V8 versions.

		// https://bugs.chromium.org/p/v8/issues/detail?id=4118
		var test1 = new String('abc');  // eslint-disable-line no-new-wrappers
		test1[5] = 'de';
		if (Object.getOwnPropertyNames(test1)[0] === '5') {
			return false;
		}

		// https://bugs.chromium.org/p/v8/issues/detail?id=3056
		var test2 = {};
		for (var i = 0; i < 10; i++) {
			test2['_' + String.fromCharCode(i)] = i;
		}
		var order2 = Object.getOwnPropertyNames(test2).map(function (n) {
			return test2[n];
		});
		if (order2.join('') !== '0123456789') {
			return false;
		}

		// https://bugs.chromium.org/p/v8/issues/detail?id=3056
		var test3 = {};
		'abcdefghijklmnopqrst'.split('').forEach(function (letter) {
			test3[letter] = letter;
		});
		if (Object.keys(Object.assign({}, test3)).join('') !==
				'abcdefghijklmnopqrst') {
			return false;
		}

		return true;
	} catch (err) {
		// We don't expect any of the above to throw, but better to be safe.
		return false;
	}
}

module.exports = shouldUseNative() ? Object.assign : function (target, source) {
	var from;
	var to = toObject(target);
	var symbols;

	for (var s = 1; s < arguments.length; s++) {
		from = Object(arguments[s]);

		for (var key in from) {
			if (hasOwnProperty.call(from, key)) {
				to[key] = from[key];
			}
		}

		if (getOwnPropertySymbols) {
			symbols = getOwnPropertySymbols(from);
			for (var i = 0; i < symbols.length; i++) {
				if (propIsEnumerable.call(from, symbols[i])) {
					to[symbols[i]] = from[symbols[i]];
				}
			}
		}
	}

	return to;
};

},{}],12:[function(require,module,exports){
'use strict';

var asap = require('asap/raw');

function noop() {}

// States:
//
// 0 - pending
// 1 - fulfilled with _value
// 2 - rejected with _value
// 3 - adopted the state of another promise, _value
//
// once the state is no longer pending (0) it is immutable

// All `_` prefixed properties will be reduced to `_{random number}`
// at build time to obfuscate them and discourage their use.
// We don't use symbols or Object.defineProperty to fully hide them
// because the performance isn't good enough.


// to avoid using try/catch inside critical functions, we
// extract them to here.
var LAST_ERROR = null;
var IS_ERROR = {};
function getThen(obj) {
  try {
    return obj.then;
  } catch (ex) {
    LAST_ERROR = ex;
    return IS_ERROR;
  }
}

function tryCallOne(fn, a) {
  try {
    return fn(a);
  } catch (ex) {
    LAST_ERROR = ex;
    return IS_ERROR;
  }
}
function tryCallTwo(fn, a, b) {
  try {
    fn(a, b);
  } catch (ex) {
    LAST_ERROR = ex;
    return IS_ERROR;
  }
}

module.exports = Promise;

function Promise(fn) {
  if (typeof this !== 'object') {
    throw new TypeError('Promises must be constructed via new');
  }
  if (typeof fn !== 'function') {
    throw new TypeError('Promise constructor\'s argument is not a function');
  }
  this._40 = 0;
  this._65 = 0;
  this._55 = null;
  this._72 = null;
  if (fn === noop) return;
  doResolve(fn, this);
}
Promise._37 = null;
Promise._87 = null;
Promise._61 = noop;

Promise.prototype.then = function(onFulfilled, onRejected) {
  if (this.constructor !== Promise) {
    return safeThen(this, onFulfilled, onRejected);
  }
  var res = new Promise(noop);
  handle(this, new Handler(onFulfilled, onRejected, res));
  return res;
};

function safeThen(self, onFulfilled, onRejected) {
  return new self.constructor(function (resolve, reject) {
    var res = new Promise(noop);
    res.then(resolve, reject);
    handle(self, new Handler(onFulfilled, onRejected, res));
  });
}
function handle(self, deferred) {
  while (self._65 === 3) {
    self = self._55;
  }
  if (Promise._37) {
    Promise._37(self);
  }
  if (self._65 === 0) {
    if (self._40 === 0) {
      self._40 = 1;
      self._72 = deferred;
      return;
    }
    if (self._40 === 1) {
      self._40 = 2;
      self._72 = [self._72, deferred];
      return;
    }
    self._72.push(deferred);
    return;
  }
  handleResolved(self, deferred);
}

function handleResolved(self, deferred) {
  asap(function() {
    var cb = self._65 === 1 ? deferred.onFulfilled : deferred.onRejected;
    if (cb === null) {
      if (self._65 === 1) {
        resolve(deferred.promise, self._55);
      } else {
        reject(deferred.promise, self._55);
      }
      return;
    }
    var ret = tryCallOne(cb, self._55);
    if (ret === IS_ERROR) {
      reject(deferred.promise, LAST_ERROR);
    } else {
      resolve(deferred.promise, ret);
    }
  });
}
function resolve(self, newValue) {
  // Promise Resolution Procedure: https://github.com/promises-aplus/promises-spec#the-promise-resolution-procedure
  if (newValue === self) {
    return reject(
      self,
      new TypeError('A promise cannot be resolved with itself.')
    );
  }
  if (
    newValue &&
    (typeof newValue === 'object' || typeof newValue === 'function')
  ) {
    var then = getThen(newValue);
    if (then === IS_ERROR) {
      return reject(self, LAST_ERROR);
    }
    if (
      then === self.then &&
      newValue instanceof Promise
    ) {
      self._65 = 3;
      self._55 = newValue;
      finale(self);
      return;
    } else if (typeof then === 'function') {
      doResolve(then.bind(newValue), self);
      return;
    }
  }
  self._65 = 1;
  self._55 = newValue;
  finale(self);
}

function reject(self, newValue) {
  self._65 = 2;
  self._55 = newValue;
  if (Promise._87) {
    Promise._87(self, newValue);
  }
  finale(self);
}
function finale(self) {
  if (self._40 === 1) {
    handle(self, self._72);
    self._72 = null;
  }
  if (self._40 === 2) {
    for (var i = 0; i < self._72.length; i++) {
      handle(self, self._72[i]);
    }
    self._72 = null;
  }
}

function Handler(onFulfilled, onRejected, promise){
  this.onFulfilled = typeof onFulfilled === 'function' ? onFulfilled : null;
  this.onRejected = typeof onRejected === 'function' ? onRejected : null;
  this.promise = promise;
}

/**
 * Take a potentially misbehaving resolver function and make sure
 * onFulfilled and onRejected are only called once.
 *
 * Makes no guarantees about asynchrony.
 */
function doResolve(fn, promise) {
  var done = false;
  var res = tryCallTwo(fn, function (value) {
    if (done) return;
    done = true;
    resolve(promise, value);
  }, function (reason) {
    if (done) return;
    done = true;
    reject(promise, reason);
  });
  if (!done && res === IS_ERROR) {
    done = true;
    reject(promise, LAST_ERROR);
  }
}

},{"asap/raw":9}],13:[function(require,module,exports){
'use strict';

//This file contains the ES6 extensions to the core Promises/A+ API

var Promise = require('./core.js');

module.exports = Promise;

/* Static Functions */

var TRUE = valuePromise(true);
var FALSE = valuePromise(false);
var NULL = valuePromise(null);
var UNDEFINED = valuePromise(undefined);
var ZERO = valuePromise(0);
var EMPTYSTRING = valuePromise('');

function valuePromise(value) {
  var p = new Promise(Promise._61);
  p._65 = 1;
  p._55 = value;
  return p;
}
Promise.resolve = function (value) {
  if (value instanceof Promise) return value;

  if (value === null) return NULL;
  if (value === undefined) return UNDEFINED;
  if (value === true) return TRUE;
  if (value === false) return FALSE;
  if (value === 0) return ZERO;
  if (value === '') return EMPTYSTRING;

  if (typeof value === 'object' || typeof value === 'function') {
    try {
      var then = value.then;
      if (typeof then === 'function') {
        return new Promise(then.bind(value));
      }
    } catch (ex) {
      return new Promise(function (resolve, reject) {
        reject(ex);
      });
    }
  }
  return valuePromise(value);
};

Promise.all = function (arr) {
  var args = Array.prototype.slice.call(arr);

  return new Promise(function (resolve, reject) {
    if (args.length === 0) return resolve([]);
    var remaining = args.length;
    function res(i, val) {
      if (val && (typeof val === 'object' || typeof val === 'function')) {
        if (val instanceof Promise && val.then === Promise.prototype.then) {
          while (val._65 === 3) {
            val = val._55;
          }
          if (val._65 === 1) return res(i, val._55);
          if (val._65 === 2) reject(val._55);
          val.then(function (val) {
            res(i, val);
          }, reject);
          return;
        } else {
          var then = val.then;
          if (typeof then === 'function') {
            var p = new Promise(then.bind(val));
            p.then(function (val) {
              res(i, val);
            }, reject);
            return;
          }
        }
      }
      args[i] = val;
      if (--remaining === 0) {
        resolve(args);
      }
    }
    for (var i = 0; i < args.length; i++) {
      res(i, args[i]);
    }
  });
};

Promise.reject = function (value) {
  return new Promise(function (resolve, reject) {
    reject(value);
  });
};

Promise.race = function (values) {
  return new Promise(function (resolve, reject) {
    values.forEach(function(value){
      Promise.resolve(value).then(resolve, reject);
    });
  });
};

/* Prototype Methods */

Promise.prototype['catch'] = function (onRejected) {
  return this.then(null, onRejected);
};

},{"./core.js":12}],14:[function(require,module,exports){
'use strict';
var strictUriEncode = require('strict-uri-encode');
var objectAssign = require('object-assign');

function encode(value, opts) {
	if (opts.encode) {
		return opts.strict ? strictUriEncode(value) : encodeURIComponent(value);
	}

	return value;
}

exports.extract = function (str) {
	return str.split('?')[1] || '';
};

exports.parse = function (str) {
	// Create an object with no prototype
	// https://github.com/sindresorhus/query-string/issues/47
	var ret = Object.create(null);

	if (typeof str !== 'string') {
		return ret;
	}

	str = str.trim().replace(/^(\?|#|&)/, '');

	if (!str) {
		return ret;
	}

	str.split('&').forEach(function (param) {
		var parts = param.replace(/\+/g, ' ').split('=');
		// Firefox (pre 40) decodes `%3D` to `=`
		// https://github.com/sindresorhus/query-string/pull/37
		var key = parts.shift();
		var val = parts.length > 0 ? parts.join('=') : undefined;

		key = decodeURIComponent(key);

		// missing `=` should be `null`:
		// http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
		val = val === undefined ? null : decodeURIComponent(val);

		if (ret[key] === undefined) {
			ret[key] = val;
		} else if (Array.isArray(ret[key])) {
			ret[key].push(val);
		} else {
			ret[key] = [ret[key], val];
		}
	});

	return ret;
};

exports.stringify = function (obj, opts) {
	var defaults = {
		encode: true,
		strict: true
	};

	opts = objectAssign(defaults, opts);

	return obj ? Object.keys(obj).sort().map(function (key) {
		var val = obj[key];

		if (val === undefined) {
			return '';
		}

		if (val === null) {
			return encode(key, opts);
		}

		if (Array.isArray(val)) {
			var result = [];

			val.slice().forEach(function (val2) {
				if (val2 === undefined) {
					return;
				}

				if (val2 === null) {
					result.push(encode(key, opts));
				} else {
					result.push(encode(key, opts) + '=' + encode(val2, opts));
				}
			});

			return result.join('&');
		}

		return encode(key, opts) + '=' + encode(val, opts);
	}).filter(function (x) {
		return x.length > 0;
	}).join('&') : '';
};

},{"object-assign":11,"strict-uri-encode":15}],15:[function(require,module,exports){
'use strict';
module.exports = function (str) {
	return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
		return '%' + c.charCodeAt(0).toString(16).toUpperCase();
	});
};

},{}]},{},[5]);
